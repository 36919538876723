
;(function (window, document, $) {

function getParameterByName(name) {
	name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
	var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		results = regex.exec(location.search);
	return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

var name = getParameterByName('utm_campaign'),
	el = $('.descriptor--footer var'),
	data = JSON.parse($('#data-campaign').html());


if(data[name]) {
	el.text(data[name]);
}



} (window, document, jQuery));
