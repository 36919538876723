
;(function (window, document, $) {

(function (window, $) {

	$('input[name="phone"]').mask('+9? (999) 999-9999');

} (window, jQuery));


} (window, document, jQuery));
