
;(function (window, document, $) {

$('body').on('click', '.trigger-modal', function (e) {
	var vent = $(this).data('vent');

	MoMan.open({
		content: $($(this).attr('href')).html(),
		afterOpen: function (wnd) {
			wnd.find('form').data('vent', vent);
			wnd.find('input[name="phone"]').mask('+9? (999) 999-9999');
		}
	})

	e.preventDefault();
});


} (window, document, jQuery));
