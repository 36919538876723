
;(function (window, document, $) {

(function (window, $) {

	$('body').on('submit', 'form', function () {
		var form = $(this);

		$.ajax({
			type: 'POST',
			url: form.attr('action'),
			data: form.serialize()
		}).done(function () {
			window.ucounter && window.ucounter.emit(form.data('vent'));

			var modal = $('#modal-gratitude');
			if(modal.length > 0) {
				MoMan.open({
					content: modal.html(),
			 		custom: 'modal-gratitude',
			 		focus: '.mm-close',
					afterOpen: function () {
						MoMan.clear();
					}
				})
			} else {
				throw Error('Element "#modal-gratitude" not found on page');
			}
		});

		return false;
	});

} (window, jQuery));

} (window, document, jQuery));
