
;(function (window, document, $) {

$('body').on('click', '.scrollto', function (e) {
	var offset = $($(this).attr('href')).offset().top;

	$('html, body').animate({
		scrollTop: offset
	}, 800);

	e.preventDefault();
});


} (window, document, jQuery));
