
;(function (window, document, $) {

ymaps.ready(function () {
	var $map = $('#map-balloon');
	var map = new ymaps.Map('map', {
		center: [$map.data('lat'), $map.data('lon')], 
		zoom: 17
	});

	$('#map').trigger('map_loaded', map);

	var Balloon = ymaps.templateLayoutFactory.createClass($('#map-balloon').html(), {
		build: function () {
			this.constructor.superclass.build.call(this);

			this._$el = $('.map-balloon', this.getParentElement());

			this.applyElementOffset();
		},
		clear: function () {
			// this.constructor.superclass.clear.call(this);
		},
		onSublayoutSizeChange: function () {
			Balloon.superclass.onSublayoutSizeChange.apply(this, arguments);

			if(!this._isElement()) {
				return;
			}

			this.applyElementOffset();

			this.events.fire('shapechange');
		},
		applyElementOffset: function () {
			this._$el.css({
				left: -this._$el[0].offsetWidth / 2,
				top: -this._$el[0].offsetHeight
			});
		},
		getShape: function () {
			if(!this._isElement(this._$el)) {
				return Balloon.superclass.getShape.call(this);
			}

			var position = this._$el.position();

			return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
				[position.left, position.top], [
					position.left + this._$el[0].offsetWidth,
					position.top + this._$el[0].offsetHeight
				]
			]));
		},
		_isElement: function () {
			return this._$el.length > 0;
		}
	});


	var placemark = new ymaps.Placemark([$map.data('lat'), $map.data('lon')], {}, {
		balloonShadow: false,
		balloonLayout: Balloon,
		balloonPanelMaxMapArea: 0,
		hideIconOnBalloonOpen: false,
		balloonOffset: [3, -48],

		iconLayout: 'default#image',
		iconImageHref: 'assets/img/map_marker.gif',
		iconImageSize: [64, 64],
		iconImageOffset: [-32, -62]
	});

	map.geoObjects.add(placemark);
	placemark.balloon.open();
});


} (window, document, jQuery));
